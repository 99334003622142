import { makeStyles } from '@material-ui/styles'
import { Button, Modal } from 'antd'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { adPopImage } from '@/constants'
import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/style/colors'

const Div = styled.div`
  .ant-modal-header {
    display: none;
  }
`

const useStyles = makeStyles({
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 0px',
  },
  btn: {
    backgroundColor: colors.data8,
    borderColor: colors.data8,
    borderRadius: 10,
    width: '95%',
    height: '100%',
    fontSize: 18,
    padding: 8,
  },
})

export default function PopUpAd(props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const visible = useSelector((state) => state.app.adPop.visible)

  const onClose = () => {
    dispatch(actions.app.closeAd())
  }

  const onBtnClick = () => {
    // dispatch(actions.app.closeAd())
    window.open('https://s.dimorder.com/crmenquiry')
  }

  const footer = (
    <div className={classes.footerContainer}>
      <Button className={classes.btn} onClick={onBtnClick}>
        立即查詢
      </Button>
    </div>
  )

  return (
    <Div>
      <Modal
        className={classes.adImage}
        visible={visible}
        centered
        footer={footer}
        onCancel={onClose}
        maskClosable={false}
        bodyStyle={{
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundImage: `url(${adPopImage.image})`,
          paddingBottom: '85%',
          borderRadius: '15px 15px 0px 0px',
        }}
      />
    </Div>
  )
}

import colors from '@/style/colors'

export const DEFAULT_NAME = 'React template'

export const pageStyle = `
  @page {
    size: A4;
    margin: 10mm;
  }

  @media print {
    body {
      zoom: 65%;
    }
  }
`

export const landscapePageStyle = `
  @page {
    size: landscape;
    margin: 10mm;
  }

  @media print {
    body {
      zoom: 65%;
    }
  }
`

export const dataColors = [
  colors.data1,
  colors.data2,
  colors.data3,
  colors.data4,
  colors.data5,
  colors.data6,
  colors.data7,
  colors.data8,
  colors.data9,
]

export const bannerImage = {
  cocaCola: {
    x1200: 'https://img.dimorder.com/1200x/public/dimsupply/600x180_DimFood.jpg',
    x2048: 'https://img.dimorder.com/2048x/public/dimsupply/1024x100_DimFood.jpg',
  },
}

export const adPopImage = {
  image: 'https://img.dimorder.com/1140x/public/dimpay/1.png',
}

export const momentLocaleZH = {
  monthsShort: [
    '一月',
    '二月',
    '三月',
    '四月',
    '五月',
    '六月',
    '七月',
    '八月',
    '九月',
    '十月',
    '十一月',
    '十二月',
  ],
}

export const FLOOR_PLAN = {
  10.2: {
    width: 740,
    height: 620,
  },
  11: {
    width: 840,
    height: 640,
  },
  12.9: {
    width: 1020,
    height: 840,
  },
  grid: 20,
  minTableSize: 76,
}

export const SHAPE = {
  SQUARE: 'square',
  RECTANGLE: 'rectangle',
  CIRCLE: 'circle',
  OVAL: 'oval',
}
